import React from "react"
import {Box, Container, Typography} from "@material-ui/core";

const Javadoc = () => {
    return (
        <Box mt={2}>
            <Container>
                <Typography>Hello there, here is some javadoc</Typography>
            </Container>
        </Box>
    )
}

export default Javadoc;
